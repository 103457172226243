import axiosIns from '@/libs/axios'

export default class FormApis {
  async allproperties() {
    return axiosIns.get('profile/all-properties')
  }

  async countryLists() {
    return axiosIns.get('countries')
  }

  async genderLists() {
    return axiosIns.get('profile/genders')
  }

  async tradeAssetLists() {
    return axiosIns.get('profile/trade-asset')
  }

  async tradeStyleLists() {
    return axiosIns.get('profile/trade-style')
  }

  async experienceLists() {
    return axiosIns.get('profile/experience')
  }
}
