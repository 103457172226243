export const alphanumeric = {

  methods: {
    AlphaNumericAndEspecialCharacters(e) {
      const char = String.fromCharCode(e.keyCode)
      if (/^[A-Za-z_0-9\\ \\_!.@$#%]+$/.test(char)) return true
      e.preventDefault()
      return true
    },
    AlphaNumeric(e) {
      const char = String.fromCharCode(e.keyCode)
      if (/^[A-Za-z_0-9\\ \\]+$/.test(char)) return true
      e.preventDefault()
      return true
    },
    onlyNumbers(e) {
      const char = String.fromCharCode(e.keyCode)
      if (/^[0-9]+$/.test(char)) return true
      e.preventDefault()
      return true
    },
    onlyAlpha(e) {
      const char = String.fromCharCode(e.keyCode)
      if (/^[A-Za-z\\ \\]+$/.test(char)) return true
      e.preventDefault()
      return true
    },
    onlyAlphaAndPersian(e) {
      const char = String.fromCharCode(e.keyCode)
      if (/^[A-Za-zا-ی\\ \\]+$/.test(char)) return true
      e.preventDefault()
      return true
    },
  },
}

export const _ = null
